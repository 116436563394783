import React from 'react';
import graphic from '../graphics/undraw_sorting_thoughts_re_fgli.svg';

const HomePage = () => {
  return (
    <div className="relative h-screen bg-light-beige-gradient">
      <div className="relative h-full flex flex-col justify-center items-center">
        <img src={graphic} alt="Graphic" className="w-full h-full object-contain hidden md:block" style={{ clipPath: 'inset(0 70% 0 0)' }} />
        <div className="absolute inset-0 flex flex-col justify-center items-center space-y-4">
          <div className="text-center mb-4">
            <h1 className="text-4xl font-bold text-black">Hi, I'm Kunal</h1>
            <p className="text-2xl text-black mt-4">Connect with me to talk about Algo Trading</p>
          </div>
          <div className="flex space-x-4">
            <button className="bg-purple-600 text-white py-3 px-6 rounded-lg shadow-lg hover:bg-purple-700 transition duration-300">Hire</button>
            <button className="bg-pink-600 text-white py-3 px-6 rounded-lg shadow-lg hover:bg-pink-700 transition duration-300">View Projects</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;