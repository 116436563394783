import React from 'react';
import { FaLinkedin, FaEnvelope, FaWhatsapp } from 'react-icons/fa';

const ContactPage = () => {
  return (
    <div className="min-h-screen flex flex-col justify-center items-center bg-light-beige-gradient">
      <h1 className="text-4xl font-bold mb-8">Contact Me</h1>
      <div className="flex flex-col items-center space-y-4">
        <a href="https://www.linkedin.com/in/kunal35" className="flex items-center space-x-2 text-blue-700 hover:text-blue-800">
          <FaLinkedin size={32} />
          <span className="text-xl">LinkedIn</span>
        </a>
        <a href="mailto:kunal.95a@gmail.com" className="flex items-center space-x-2 text-red-600 hover:text-red-700">
          <FaEnvelope size={32} />
          <span className="text-xl">kunal.95a@gmail.com</span>
        </a>
        <a href="https://api.whatsapp.com/send?phone=+919553324251&text=Hi" className="flex items-center space-x-2 text-green-600 hover:text-green-700">
          <FaWhatsapp size={32} />
          <span className="text-xl">WhatsApp</span>
        </a>
      </div>
    </div>
  );
};

export default ContactPage;