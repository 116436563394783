import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  return (
    <nav className="bg-gray-800 p-4 sticky top-0 z-50">
      <div className="container mx-auto flex justify-between items-center">
        <div className="hidden md:flex justify-center w-full space-x-4 text-lg font-bold">
          <Link to="/" className="text-gray-300 hover:text-white">Home</Link>
          <Link to="/blog" className="text-gray-300 hover:text-white">Blog</Link>
          <Link to="/backtest" className="text-gray-300 hover:text-white">Projects</Link>
          <Link to="/contact" className="text-gray-300 hover:text-white">Contact</Link>
        </div>
        <div className="md:hidden flex items-center">
          <button onClick={toggleMenu} className="text-gray-300 hover:text-white focus:outline-none">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path>
            </svg>
          </button>
        </div>
      </div>
      {isOpen && (
        <div className="fixed inset-0 bg-gray-800 flex flex-col items-center justify-center space-y-4 z-50">
          <Link to="/" className="text-gray-300 hover:text-white text-2xl" onClick={closeMenu}>Home</Link>
          <Link to="/blog" className="text-gray-300 hover:text-white text-2xl" onClick={closeMenu}>Blog</Link>
          <Link to="/backtest" className="text-gray-300 hover:text-white text-2xl" onClick={closeMenu}>Projects</Link>
          <Link to="/contact" className="text-gray-300 hover:text-white text-2xl" onClick={closeMenu}>Contact</Link>
          <button onClick={closeMenu} className="absolute bottom-4 text-gray-300 hover:text-white focus:outline-none">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
            </svg>
          </button>
        </div>
      )}
    </nav>
  );
};

export default NavBar;