import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto'; // Ensures Chart.js is registered

const BacktestCard = ({ title, equityCurveData = { labels: [], data: [] }, metrics = [], isLoading = false }) => {
  const headerColors = React.useMemo(
    () => [
      'from-blue-500 to-indigo-500',
      'from-green-500 to-teal-500',
      'from-purple-500 to-pink-500',
    ],
    []
  );
  const [headerColor, setHeaderColor] = useState('');

  useEffect(() => {
    setHeaderColor(headerColors[Math.floor(Math.random() * headerColors.length)]);
  }, [headerColors]);

  const chartData = {
    labels: equityCurveData.labels,
    datasets: [
      {
        label: 'Equity Curve',
        data: equityCurveData.data,
        borderColor: '#10B981',
        backgroundColor: 'rgba(16, 185, 129, 0.2)',
        tension: 0.4,
        fill: true,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { display: false },
    },
    scales: {
      x: {
        title: {
          display: false,
          text: 'Date',
          font: { size: 14, weight: 'bold' },
        },
        ticks: { maxTicksLimit: 5, color: '#6B7280', font: { size: 12 } },
        grid: { display: false },
      },
      y: {
        title: {
          display: false,
          text: 'Value (₹)',
          font: { size: 14, weight: 'bold' },
        },
        ticks: {
          maxTicksLimit: 5,
          callback: (value) =>
            value >= 100000
              ? `${value / 100000}L`
              : value >= 1000
              ? `${value / 1000}K`
              : value,
          color: '#6B7280',
          font: { size: 12 },
        },
        grid: { display: false },
        beginAtZero: false,
      },
    },
  };

  return (
    <div className="bg-white shadow-xl rounded-xl overflow-hidden w-full max-w-lg m-4 transform transition-transform hover:scale-105">
      <div className={`bg-gradient-to-r ${headerColor} p-6 text-center text-white`}>
        <h2 className="text-2xl font-bold">{isLoading ? 'Loading...' : title}</h2>
      </div>

      <div className="p-6">
        <div className="mb-6 h-64">
          {isLoading ? (
            <div className="flex items-center justify-center h-full bg-gray-200 rounded-lg">
              <span className="text-gray-500">Loading Equity Curve...</span>
            </div>
          ) : (
            <Line data={chartData} options={chartOptions} />
          )}
        </div>

        <div className="bg-gray-100 p-4 rounded-lg shadow-inner">
          <div className="grid grid-cols-2 gap-4">
            {isLoading
              ? Array.from({ length: 4 }).map((_, index) => (
                  <div
                    key={index}
                    className="flex flex-col items-center justify-center bg-gray-300 animate-pulse shadow-md p-3 rounded-lg"
                  >
                    <span className="h-4 w-12 bg-gray-400 rounded mb-2"></span>
                    <span className="h-6 w-16 bg-gray-400 rounded"></span>
                  </div>
                ))
              : metrics.map((metric, index) => (
                  <div
                    key={index}
                    className="flex flex-col items-center justify-center bg-white shadow-md p-3 rounded-lg"
                  >
                    <span className="text-sm font-medium text-gray-500">{metric.name}</span>
                    <span className="text-xl font-bold text-gray-800">₹{metric.value || 'N/A'}</span>
                  </div>
                ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BacktestCard;
