import React, { useEffect, useState } from 'react';
import BacktestCard from './components/BacktestCard';

const BacktestPage = () => {
  const [backtests, setBacktests] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Track loading state

  useEffect(() => {
    const fetchBacktests = async () => {
      try {
        const response = await fetch(
          `/api/sheets-data?spreadsheetId=14xuR02x037CMoFOEVfrMl4Dq_syDlRfxQ83TYi9qNtY`
        );
        const data = await response.json();
        console.log('API Response:', data); // Debugging log
        setBacktests(data);
      } catch (error) {
        console.error('Error fetching backtest data:', error);
      } finally {
        setIsLoading(false); // Stop loading after fetch
      }
    };

    fetchBacktests();
  }, []);

  return (
    <div className="min-h-screen bg-light-beige-gradient p-8">
      <h1 className="text-4xl font-bold mb-8 text-center">Backtest Results</h1>
      <div className="flex flex-wrap justify-center">
        {isLoading
          ? Array.from({ length: 2 }).map((_, index) => (
              // Show placeholder loading cards
              <BacktestCard key={index} isLoading={true} />
            ))
          : backtests.map((backtest, index) => (
              <BacktestCard
                key={index}
                title={backtest.strategyName}
                equityCurveData={{
                  labels: backtest.equityCurve.map((entry) =>
                    entry.date ? entry.date : 'Unknown Date'
                  ),
                  data: backtest.equityCurve.map((entry) =>
                    entry.value !== null && entry.value !== undefined
                      ? entry.value
                      : 0
                  ),
                }}
                metrics={backtest.metrics}
              />
            ))}
      </div>
    </div>
  );
};

export default BacktestPage;
